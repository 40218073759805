import { CommonProps, InputTypes } from './Field'
import React, { FC, useEffect, useState } from 'react'
import classnames from 'classnames'
import { propEq } from 'ramda'
import styles from './Field.module.css'

type Option = {
  label?: string
  value: string
}

export type SelectProps = CommonProps & {
  onChange: (v?: Option) => void
  options: Array<Option>
  type: InputTypes.SELECT
}

const SelectField: FC<SelectProps> = props => {
  const [value, setValue] = useState(props.initialValue)
  useEffect(() => {
    setValue(props.initialValue)
  }, [props.initialValue])
  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value)
    props.onChange(props.options.find(propEq('value', e.target.value)))
  }
  return (
    <select
      onChange={handleOnChange}
      value={value}
      className={classnames({ [styles.placeholder]: !value })}
    >
      {props.options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  )
}

export default SelectField

import React, { FC } from 'react'

type LogoTextProps = {
  className: string
}

const LogoText: FC<LogoTextProps> = ({ className }) => (
  <svg
    className={className}
    width="160"
    height="16"
    viewBox="0 0 160 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.8143 14.7469L95.0198 9.87698H95.8922L97.4831 13.019C97.9682 13.9706 98.9593 14.1261 100.045 14.1261C101.539 14.1261 102.762 13.3696 102.762 11.933C102.762 10.4977 101.772 9.75963 99.9095 9.13885L98.842 8.7896C96.4167 7.99351 95.1161 6.5003 95.1161 4.24915C95.1161 1.39706 97.9114 0 100.841 0C101.907 0 103.073 0.135746 104.003 0.465216L104.857 5.08343H103.983L102.335 1.97823C102.005 1.35747 101.191 1.24152 100.375 1.24152C98.9973 1.24152 97.8324 1.95984 97.8324 3.31731C97.8324 4.26895 98.2791 5.19937 99.7144 5.66602L100.782 6.01526C103.596 6.9273 105.556 8.07127 105.556 10.847C105.556 13.7373 102.665 15.3677 99.8896 15.3677C98.3765 15.3677 97.1153 15.2121 95.8143 14.7469ZM3.74575 10.0509H9.29442L11.3702 15.1159H14.6705L8.63545 0.407239H6.01529L0 15.1159H1.68835L3.74575 10.0509ZM6.50028 3.23954L8.71324 8.61566H4.32692L6.50028 3.23954ZM17.7885 0.407239H21.1835L25.8979 11.0987L30.6137 0.407239H32.3402L25.8399 15.1159H24.3071L17.7885 0.407239ZM44.7439 10.0509H39.1939L37.1381 15.1159H35.4497L41.465 0.40724H44.0837L50.1188 15.1159H46.82L44.7439 10.0509ZM44.1615 8.61566L41.9501 3.23954L39.7767 8.61566H44.1615ZM67.5751 10.362V0.407239H69.1472V15.1159H67.5751L58.1054 4.96748V15.1159H56.5343V0.407239H58.1054L67.5751 10.362ZM75.6348 1.84248H80.8938V15.1159H83.9975V1.84248H89.2562V0.407239H75.6348V1.84248ZM110.971 1.84248H116.23V15.1159H119.335V1.84248H124.594V0.407239H110.971V1.84248ZM131.154 10.0509H136.702L138.78 15.1159H142.079L136.043 0.40724H133.425L127.41 15.1159H129.097L131.154 10.0509ZM133.91 3.23954L136.121 8.61566H131.737L133.91 3.23954ZM150.571 15.1159H153.676V8.49831L159.634 0.407239H157.732L152.958 6.80994L148.262 0.407239H144.615L150.571 8.47851V15.1159Z"
      fill="#022B54"
    />
  </svg>
)

export default LogoText

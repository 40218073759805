import React, { FC } from 'react'

const Others: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9658 8.02438C18.6575 7.72625 18.1603 7.72625 17.852 8.02438L10.2839 15.343L7.14758 12.3101C6.83929 12.012 6.34214 12.012 6.03385 12.3101C5.72179 12.6119 5.72179 13.104 6.03385 13.4058L9.72703 16.9772C10.0353 17.2753 10.5325 17.2753 10.8408 16.9772L18.9658 9.12004C19.2778 8.81827 19.2778 8.32615 18.9658 8.02438Z"
      fill="#022B54"
    />
  </svg>
)

export default Others

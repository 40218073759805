import { ApiRequest, ApiResponse } from 'src/interfaces/api'
import { connect, ConnectedProps } from 'react-redux'
import { prefetch, refetch } from 'react-suspense-fetch'
import React, { FC, useEffect, useState } from 'react'
import api from 'src/api'
import EmptyResults from 'src/components/search/EmptyResults'
import HomeCard from 'src/components/search/HomeCard'
import LoadMoreHomes from 'src/components/search/LoadMoreHomes'
import { loadPrices } from 'src/store/actions/thunks'
import { prop } from 'ramda'
import ResultsHeader from 'src/components/search/ResultsHeader'
import { RootState } from 'src/interfaces/store/reducers'
import styles from './Results.module.css'

const mapStateToProps = ({
  selectedGuests,
  selectedOrder,
  selectedRegion,
  selectedDates,
  coupon,
  regions,
}: RootState) => ({
  coupon,
  regions,
  selectedDates,
  selectedGuests,
  selectedOrder,
  selectedRegion,
})

const mapDispatchToProps = {
  loadPrices,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ResultsProps = ConnectedProps<typeof connector> & { page?: number }

let firstCall = true

const fetchResults = async (
  options: ApiRequest['homes'] & { more?: ApiResponse['homes']['results'] }
) => {
  if (firstCall) {
    firstCall = false
    return {}
  }
  return api.homes(options)
}

const initialResults = prefetch<ApiResponse['homes'], ApiRequest['homes']>(fetchResults, {})

const Results: FC<ResultsProps> = ({
  regions,
  coupon,
  selectedGuests,
  selectedOrder,
  selectedRegion,
  selectedDates,
  loadPrices,
  page = 1,
}) => {
  const [results, setResult] = useState(initialResults)
  useEffect(() => {
    setResult(
      refetch<ApiResponse['homes'], ApiRequest['homes']>(results, {
        checkIn: selectedDates?.[0],
        checkOut: selectedDates?.[1],
        guests: selectedGuests,
        order: selectedOrder,
        page,
        region: selectedRegion === 'any' ? '' : selectedRegion,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuests, selectedOrder, selectedRegion, selectedDates, page])

  useEffect(() => {
    if (results?.results?.length && selectedDates?.length) {
      loadPrices({
        checkIn: selectedDates?.[0],
        checkOut: selectedDates?.[1],
        coupon,
        ids: results.results.map(prop('id')),
        page,
      })
    }
  }, [loadPrices, results, selectedDates, coupon, page])

  const regionName =
    selectedRegion === 'any'
      ? ''
      : Object.values(regions).find(({ id }) => id === selectedRegion)?.name
  return results.count !== 0 ? (
    <>
      <div className={styles.container}>
        {page === 1 && results.count ? <ResultsHeader count={results.count} /> : null}
        <div className={styles.list}>
          {results.results?.map(home => (
            <HomeCard home={home} key={home.id} />
          ))}
        </div>
      </div>
      {results?.count && page * 10 > results.count ? null : <LoadMoreHomes page={page + 1} />}
    </>
  ) : (
    <EmptyResults regionName={regionName} />
  )
}

export default connector(Results)

import React, { FC } from 'react'

const Bath: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9995 17.5C17.499 17.5 20.4995 13.5 20.4995 10.5H3.49951C3.49951 13.5 6.50004 17.5 11.9995 17.5Z"
      stroke="#022B54"
    />
    <path d="M5.5 10V7.5H8.5V9" stroke="#022B54" />
    <path d="M7.20703 16.293L5.79282 17.7072" stroke="#022B54" />
    <path d="M16.793 16.293L18.2072 17.7072" stroke="#022B54" />
  </svg>
)

export default Bath

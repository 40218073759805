import React, { FC } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './NavLink.module.css'

type NavLinkProps = {
  active?: boolean
  href: string
  Icon?: FC
  label: string
}

const NavLink: FC<NavLinkProps> = ({ label, href, active, Icon }) => (
  <Link
    className={classnames(styles.nav, { [styles.active]: active })}
    to={{
      pathname: href,
    }}
  >
    {label} {Icon ? <Icon /> : null}
  </Link>
)

export default NavLink

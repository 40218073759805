import { connect, ConnectedProps } from 'react-redux'
import React, { FC, Suspense, useEffect, useRef, useState } from 'react'
import Results from './Results'
import { RootState } from 'src/interfaces/store/reducers'
import styles from './LoadMoreHomes.module.css'
import VizSensor from 'react-visibility-sensor'

const mapStateToProps = ({
  selectedGuests,
  selectedOrder,
  selectedRegion,
  selectedDates,
}: RootState) => ({
  selectedDates,
  selectedGuests,
  selectedOrder,
  selectedRegion,
})

const connector = connect(mapStateToProps)

type LoadMoreHomesProps = ConnectedProps<typeof connector> & { page?: number }

const LoadMoreHomes: FC<LoadMoreHomesProps> = ({
  page,
  selectedDates,
  selectedGuests,
  selectedOrder,
  selectedRegion,
}) => {
  const [wasVisible, setWasVisible] = useState(false)
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    setWasVisible(false)
  }, [selectedDates, selectedGuests, selectedOrder, selectedRegion])
  return (
    <>
      <VizSensor onChange={isVisible => isVisible && setWasVisible(isVisible)}>
        <div>
          <button className={styles['scroll-loader-hide']}>.</button>
        </div>
      </VizSensor>
      {wasVisible && (
        <Suspense
          fallback={<button className={styles['scroll-loader']}>Loading more homes...</button>}
        >
          <Results page={page} />
        </Suspense>
      )}
    </>
  )
}

export default connector(LoadMoreHomes)

import React, { FC } from 'react'
import HomeSpecs from './HomeSpecs'
import { Link } from 'react-router-dom'
import styles from './Header.module.css'

type HeaderProps = {
  bathroomsCount: number
  cityName: string
  maxOccupancy: number
  regionName: string
  roomsCount: number
  title: string
}

const Header: FC<HeaderProps> = ({
  regionName,
  cityName,
  title,
  roomsCount,
  bathroomsCount,
  maxOccupancy,
}) => (
  <div className={styles.container}>
    <div className={styles.location}>
      <Link to={`/regions/${regionName}`}>{regionName}</Link> ・ {cityName}
    </div>
    <div className={styles.title}>{title}</div>
    <HomeSpecs
      roomsCount={roomsCount}
      bathroomsCount={bathroomsCount}
      maxOccupancy={maxOccupancy}
    />
  </div>
)

export default Header

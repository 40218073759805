import { ApiRequest, ApiResponse } from 'src/interfaces/api'
import { Home } from 'src/interfaces/home'
import homes from 'src/fixtures/homes'
import { HomesOrder } from './interfaces/filter'
import { propEq } from 'ramda'
// import raw from 'raw.macro'
import regions from 'src/fixtures/regions'
import waait from 'waait'

// const homesQuery = raw('./graphql/queries/homes.gql')
// const homeQuery = raw('./graphql/queries/home.gql')
// const regionsQuery = raw('./graphql/queries/regions.gql')
// const homesPricingQuery = raw('./graphql/queries/homesPricing.gql')

type APIHandler = {
  home: (p: ApiRequest['home']) => Promise<ApiResponse['home']>
  homes: (p: ApiRequest['homes']) => Promise<ApiResponse['homes']>
  homesPricing: (p: ApiRequest['homesPricing']) => Promise<ApiResponse['homesPricing']>
  regions: () => Promise<ApiResponse['regions']>
}

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

const API: APIHandler = {
  regions: async () => regions,
  homes: async ({ checkIn, checkOut, region, order, page = 1, guests }) => {
    await waait(1000)
    const checkInDate = checkIn ? new Date(checkIn) : null
    const checkOutDate = checkOut ? new Date(checkOut) : null
    const filteredHomes = homes.filter(({ maxOccupancy, regionName }) => {
      if (
        checkInDate &&
        checkOutDate &&
        checkOutDate.getTime() - checkInDate.getTime() > 15 * DAY
      ) {
        return false
      }
      if (guests && maxOccupancy < parseInt(guests)) {
        return false
      }
      if (region) {
        const targetRegion = regions.find(propEq('id', region))
        if (targetRegion?.name !== regionName) {
          return false
        }
      }
      return true
    })
    if (order && order !== HomesOrder.RELEVANCE) {
      filteredHomes.sort((a, b) => {
        const priceA = a.seasonPricing.lowSeason.maxPrice
        const priceB = b.seasonPricing.lowSeason.maxPrice
        if (order === HomesOrder.PRICE_ASC) {
          return priceA - priceB
        } else {
          return priceB - priceA
        }
      })
    }

    return { count: filteredHomes.length, results: filteredHomes.slice((page - 1) * 10, page * 10) }
  },
  homesPricing: async ({ checkIn, checkOut, ids }) => {
    await waait(1500)
    const checkInDate = checkIn ? new Date(checkIn) : null
    const checkOutDate = checkOut ? new Date(checkOut) : null
    if (checkInDate && checkOutDate) {
      const numberOfNights = Math.ceil((checkOutDate.getTime() - checkInDate.getTime()) / DAY)
      const pricedHouses = homes.filter(({ id }) => ids.includes(id))
      return pricedHouses.map(({ id, seasonPricing: { lowSeason: { maxPrice } } }) => ({
        homeId: id,
        numberOfNights,
        total: maxPrice * numberOfNights,
      }))
    } else {
      return []
    }
  },
  home: async ({ id }) => homes.find(propEq('id', id)) as Home,
}
export default API

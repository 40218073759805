import React, { FC } from 'react'
import High from 'src/assets/icons/High'
import { Home } from 'src/interfaces/home'
import Low from 'src/assets/icons/Low'
import styles from './SeasonPricing.module.css'

type SeasonPricingProps = {
  seasonPricing: Home['seasonPricing']
}

type TierPricingProps = {
  Icon: FC
  maxPrice: number
  minPrice: number
}

const numberFormatter = new Intl.NumberFormat()

const TierPricing: FC<TierPricingProps> = ({ minPrice, maxPrice, Icon }) => (
  <div className={styles['price-tier']}>
    <div className={styles['price-description']}>
      <Icon /> <div>Budget Season</div>
    </div>
    <div className={styles.price}>
      ${numberFormatter.format(minPrice)} - ${numberFormatter.format(maxPrice)}
    </div>
    <div className={styles.night}>per night</div>
  </div>
)

const SeasonPricing: FC<SeasonPricingProps> = ({ seasonPricing }) => (
  <div className={styles['season-price']}>
    <TierPricing {...seasonPricing.lowSeason} Icon={Low} />
    <TierPricing {...seasonPricing.highSeason} Icon={High} />
  </div>
)

export default SeasonPricing

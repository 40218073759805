import React, { FC } from 'react'

const Laundry: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.125 2H17.8333C19.5821 2 21 3.4328 21 5.2V18.8C21 20.5672 19.5821 22 17.8333 22H5.16667C3.41788 22 2 20.5672 2 18.8V7.6V5.2C2 3.4328 3.41788 2 5.16667 2H9.125ZM3.5835 18.8001V7.60006H5.9585C7.70729 7.60006 9.12516 6.16725 9.12516 4.40005V3.60005H17.8335C18.7083 3.60005 19.4168 4.31605 19.4168 5.20005V18.8001C19.4168 19.6841 18.7083 20.4001 17.8335 20.4001H5.16683C4.29204 20.4001 3.5835 19.6841 3.5835 18.8001ZM5.16683 3.60005H7.54183V4.40005C7.54183 4.69125 7.46504 4.96485 7.32966 5.20005C7.05654 5.67845 6.54433 6.00005 5.9585 6.00005H3.5835V5.20005C3.5835 4.90885 3.66029 4.63525 3.79566 4.40005C3.81387 4.36805 3.83366 4.33605 3.85425 4.30565C4.13845 3.88005 4.62058 3.60005 5.16683 3.60005ZM11.4998 17.0664C13.1861 17.0664 14.5873 15.8376 14.8763 14.2184L14.3942 14.5032C13.6729 14.9304 12.7799 14.9256 12.0635 14.4904L11.4048 14.0904C11.1808 13.9544 10.9013 13.9528 10.6765 14.0864L9.9727 14.5032C9.44861 14.8136 8.83428 14.896 8.2627 14.7512C8.73295 16.1 10.0044 17.0664 11.4998 17.0664ZM14.3042 12.7176C14.449 12.6312 14.6018 12.5624 14.7578 12.5112C14.3065 11.1304 13.0185 10.1336 11.5001 10.1336C9.82966 10.1336 8.4387 11.3392 8.13232 12.9368L8.45137 13.1304C8.67462 13.2664 8.95328 13.268 9.17812 13.1344L9.88191 12.7176C10.6039 12.2896 11.4977 12.2944 12.2142 12.7304L12.8728 13.1304C13.0961 13.2664 13.3747 13.268 13.6004 13.1344L14.3042 12.7176ZM11.4998 18.8C14.3419 18.8 16.6457 16.472 16.6457 13.6C16.6457 10.728 14.3419 8.39998 11.4998 8.39998C8.65775 8.39998 6.354 10.728 6.354 13.6C6.354 16.472 8.65775 18.8 11.4998 18.8ZM17.8333 5.99999C17.8333 6.44159 17.4787 6.79999 17.0417 6.79999C16.6047 6.79999 16.25 6.44159 16.25 5.99999C16.25 5.55839 16.6047 5.19999 17.0417 5.19999C17.4787 5.19999 17.8333 5.55839 17.8333 5.99999ZM11.5002 5.19999C11.0632 5.19999 10.7085 5.55839 10.7085 5.99999C10.7085 6.44159 11.0632 6.79999 11.5002 6.79999H13.8752C14.3122 6.79999 14.6668 6.44159 14.6668 5.99999C14.6668 5.55839 14.3122 5.19999 13.8752 5.19999H11.5002Z"
      fill="#022B54"
    />
  </svg>
)

export default Laundry

import { connect, ConnectedProps } from 'react-redux'
import React, { FC, Suspense, useEffect } from 'react'
import Loading from 'src/components/search/Loading'
import { loadRegions } from 'src/store/actions/thunks'
import Results from 'src/components/search/Results'
import SearchBar from 'src/components/search/SearchBar'

const mapDispatchToProps = {
  loadRegions,
}

const connector = connect(null, mapDispatchToProps)

type SearchProps = ConnectedProps<typeof connector>

const Search: FC<SearchProps> = ({ loadRegions }) => {
  useEffect(() => {
    const { pathname } = window.location
    const regionNameMatch = pathname.match(/regions\/(.*)/)
    if (regionNameMatch) {
      const regionName = regionNameMatch[1]
      loadRegions(regionName)
    }
    loadRegions()
  }, [loadRegions])
  return (
    <>
      <SearchBar />
      <Suspense fallback={<Loading />}>
        <Results />
      </Suspense>
    </>
  )
}

export default connector(Search)

export type Region = {
  id: string
  name: string
  stateName: string
}

export enum HomesOrder {
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC',
  RELEVANCE = 'RELEVANCE',
}

export type OrderOption = {
  label?: string
  value: HomesOrder
}

export type GuestOption = {
  label?: string
  value: string
}

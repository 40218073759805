import React, { FC } from 'react'
import Bath from 'src/assets/details/Bath'
import Rooms from 'src/assets/details/Rooms'
import styles from './HomeSpecs.module.css'
import User from 'src/assets/details/User'

type HomeSpecsProps = {
  bathroomsCount: number
  maxOccupancy: number
  roomsCount: number
}

const HomeSpecs: FC<HomeSpecsProps> = ({ roomsCount, bathroomsCount, maxOccupancy }) => (
  <div className={styles.details}>
    <Rooms />
    <div>{roomsCount} Bedrooms</div>
    <Bath />
    <div>{bathroomsCount} Bathrooms</div>
    <User />
    <div>{maxOccupancy} Guests</div>
  </div>
)

export default HomeSpecs

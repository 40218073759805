import EmptyCard from 'src/components/search/EmptyCard'
import React from 'react'
import styles from './Loading.module.css'

export default () => (
  <div className={styles.container}>
    <div className={styles.text1}>Please wait</div>
    <div className={styles.text2}>
      <b>Loading</b> homes
    </div>
    <EmptyCard />
    <hr className={styles.hr} />
    <EmptyCard />
    <hr className={styles.hr} />
    <EmptyCard />
  </div>
)

import React, { FC } from 'react'
import classnames from 'classnames'
import styles from './EmptyCard.module.css'

type EmptyCardProps = {
  classNames?: string
}

const EmptyCard: FC<EmptyCardProps> = ({ classNames = '' }) => (
  <div className={classnames(classNames, styles.container)}>
    <div className={styles['empty-card']} />
    <div className={styles['description-container']}>
      <div className={styles.line} />
      <div className={styles.line} />
      <div className={styles.line} />
      <div className={styles.line} />
      <div className={styles.line} />
      <div className={styles.line} />
      <div className={styles.line} />
    </div>
  </div>
)

export default EmptyCard

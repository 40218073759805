import React, { FC } from 'react'

const AC: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3738 11.5L7.3082 13.2233L3.42088 12.2092L3 13.7385L5.31659 14.3429L3.14383 15.5643L3.9569 16.9355L6.12968 15.7141L5.50894 17.9697L7.07967 18.3796L8.12129 14.5945L11.187 12.8712V16.3179L8.3412 19.0888L9.49106 20.2084L11.187 18.5572V21H12.8131V18.5572L14.5089 20.2084L15.6588 19.0888L12.8131 16.318V12.8712L15.8787 14.5945L16.9203 18.3796L18.491 17.9697L17.8703 15.7141L20.0432 16.9356L20.8562 15.5644L18.6834 14.343L21 13.7385L20.5792 12.2092L16.6918 13.2234L13.6261 11.5L16.6918 9.77661L20.5792 10.7908L21 9.26142L18.6834 8.65702L20.8562 7.43554L20.0432 6.06433L17.8703 7.2858L18.491 5.03019L16.9203 4.6204L15.8787 8.40539L12.8131 10.1288V6.68211L15.6588 3.9113L14.5089 2.79172L12.8131 4.44294V2H11.187V4.44296L9.49106 2.79171L8.3412 3.9113L11.187 6.68213V10.1288L8.12129 8.40543L7.07967 4.6204L5.50894 5.0302L6.12968 7.28585L3.9569 6.06441L3.14383 7.43562L5.31659 8.65704L3 9.26143L3.42088 10.7908L7.3082 9.77663L10.3738 11.5Z"
      fill="#022B54"
    />
  </svg>
)

export default AC

import { connect, ConnectedProps } from 'react-redux'
import React, { FC } from 'react'
import { HomePricing } from 'src/interfaces/home'
import { RootState } from 'src/interfaces/store/reducers'
import seasonPricingStyles from './SeasonPricing.module.css'
import styles from './CalculatedPricing.module.css'

const mapStateToProps = ({ prices }: RootState) => ({
  prices,
})

const connector = connect(mapStateToProps)

type CalculatedPricingProps = ConnectedProps<typeof connector> & {
  loading: boolean
  price: HomePricing
}

const Loading: FC = () => (
  <div className={styles.container}>
    <div className={styles.line} />
    <div className={styles.line} />
    <div className={styles.line} />
  </div>
)

const numberFormatter = new Intl.NumberFormat()

const CalculatedPricing: FC<CalculatedPricingProps> = ({ loading, price }) => {
  if (!price || loading) {
    return <Loading />
  }

  return (
    <div className={seasonPricingStyles['season-price']}>
      <div className={seasonPricingStyles['price-tier']}>
        <div className={seasonPricingStyles['price-description']}>
          <div>Total ・ {price.numberOfNights} nights</div>
        </div>
        <div className={seasonPricingStyles.price}>${numberFormatter.format(price.total)}</div>
        <div className={seasonPricingStyles.night}>
          ${numberFormatter.format(+(price.total / price.numberOfNights).toFixed(0))} per night
        </div>
      </div>
    </div>
  )
}

export default connector(CalculatedPricing)

import React, { FC } from 'react'

const Parking: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.63636 12C4.63636 7.93318 7.93318 4.63636 12 4.63636C16.0669 4.63636 19.3636 7.93318 19.3636 12C19.3636 16.0669 16.0669 19.3636 12 19.3636C7.93318 19.3636 4.63636 16.0669 4.63636 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9705 7.02944 21 12 21C16.9705 21 21 16.9705 21 12C21 7.02944 16.9705 3 12 3ZM12.4091 12H11.1818V9.54545H12.4091C13.0869 9.54545 13.6364 10.0949 13.6364 10.7727C13.6364 11.4505 13.0869 12 12.4091 12ZM11.1818 13.6364V16.0909H9.54545V13.6364V7.90909H11.1818H12.4091C13.9906 7.90909 15.2727 9.19118 15.2727 10.7727C15.2727 12.3543 13.9906 13.6364 12.4091 13.6364H11.1818Z"
      fill="#022B54"
    />
  </svg>
)

export default Parking

import { CommonProps, InputTypes } from './Field'
import React, { FC, useEffect, useState } from 'react'
import { DateRangePicker } from 'rsuite'
import styles from './DateField.module.css'

export type DateProps = CommonProps & {
  onChange: (value: Array<string | undefined>) => void
  type: InputTypes.DATE
}

type DateValueProps = {
  value: Array<Date | undefined>
}

const parseDate = (dt: Date) => ({
  day: dt.getDate(),
  month: dt.getMonth(),
  year: dt.getFullYear(),
})

const monthAbbr = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const leftPad = (n: number) => (n < 10 ? `0${n}` : `${n}`)

const DateValue: FC<DateValueProps> = props => {
  const date1 = props.value?.[0]
  const date2 = props.value?.[1]
  if (!date1 || !date2) {
    return null
  }

  const { day: day1, month: month1, year: year1 } = parseDate(date1)
  const { day: day2, month: month2, year: year2 } = parseDate(date2)
  if (year1 === year2) {
    if (month1 === month2) {
      return <div>{`${monthAbbr[month1]} ${leftPad(day1)} - ${leftPad(day2)}, ${year1}`}</div>
    } else {
      return (
        <div>{`${monthAbbr[month1]} ${leftPad(day1)} - ${monthAbbr[month2]} ${leftPad(
          day2
        )}, ${year1}`}</div>
      )
    }
  } else {
    return (
      <div>{`${monthAbbr[month1]} ${leftPad(day1)}, ${year1} - ${monthAbbr[month2]} ${leftPad(
        day2
      )}, ${year2}`}</div>
    )
  }
}

const DateField: FC<DateProps> = props => {
  const [value, setValue] = useState(props?.initialValue?.split(',').map(d => new Date(d)) as any)
  useEffect(() => {
    setValue((props.initialValue?.split(',').map(d => new Date(d)) as any) ?? [])
  }, [props.initialValue])

  const handleOnChange = (dates: Array<Date | undefined>) => {
    const parsedDates = dates.map(d => d?.toISOString().split('T')[0])
    props.onChange(parsedDates)
    setValue(dates)
  }

  return (
    <DateRangePicker
      renderValue={v => <DateValue value={v} />}
      cleanable={false}
      value={value}
      disabledDate={DateRangePicker.beforeToday()}
      onChange={handleOnChange}
      className={styles.datepicker}
      placeholder={props.placeholder}
    />
  )
}

export default DateField

import { drop, filter, fromPairs, join, map, pipe, split, toPairs } from 'ramda'

export const clearQueryString = () => {
  const searchString = joinQueryString({})
  if (window.history.pushState) {
    const { protocol, host, pathname } = window.location
    const newurl = `${protocol}//${host}${pathname}${searchString === '?' ? '' : searchString}`
    window.history.pushState({ path: newurl }, '', newurl)
  }
}

export const pushQueryString = (key: string, value: string) => {
  const qs = extractQueryStringToObject(window.location.search)
  qs[key] = value
  const searchString = joinQueryString(qs)
  if (window.history.pushState) {
    const { protocol, host, pathname } = window.location
    const newurl = `${protocol}//${host}${pathname}${searchString === '?' ? '' : searchString}`
    window.history.pushState({ path: newurl }, '', newurl)
  }
}

const joinQueryString: (qs: Record<string, string>) => string = pipe<
  Record<string, string>,
  Array<[string, string]>,
  Array<[string, string]>,
  Array<string>,
  string,
  string
>(
  toPairs,
  filter<[string, string]>(pair => Boolean(pair[1])),
  map(
    pipe<[string, string], Array<string>, string>(
      map(e => encodeURIComponent(e)),
      join('=')
    )
  ),
  join('&'),
  s => `?${s}`
)

export const extractQueryStringToObject = pipe<
  string,
  string,
  Array<string>,
  Array<[string, string]>,
  Array<[string, string]>,
  Record<string, string>
>(
  drop(1),
  split('&'),
  map(
    pipe<string, [string, string], [string, string]>((split as any)('='), ([k, v]) => [
      k,
      decodeURIComponent(v),
    ])
  ),
  filter(([k, _]) => Boolean(k)),
  fromPairs
)

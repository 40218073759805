import { connect, ConnectedProps } from 'react-redux'
import React, { FC, useEffect } from 'react'
import Amenities from 'src/components/details/Amenities'
import Description from 'src/components/details/Description'
import Header from 'src/components/details/Header'
import { loadHomeDetails } from 'src/store/actions/thunks'
import PhotoCarousel from 'src/components/details/PhotoCarousel'
import { RootState } from 'src/interfaces/store/reducers'
import { useParams } from 'react-router-dom'

const mapStateToProps = ({ home }: RootState) => ({
  home,
})

const mapDispatchToProps = {
  loadHomeDetails,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type DetailsProps = ConnectedProps<typeof connector>

const Details: FC<DetailsProps> = ({ loadHomeDetails, home }) => {
  const { id } = useParams()
  useEffect(() => {
    loadHomeDetails(id)
  }, [id, loadHomeDetails])
  if (!home) return null
  const {
    photos,
    description,
    amenities,
    hasPool,
    regionName,
    cityName,
    title,
    roomsCount,
    bathroomsCount,
    maxOccupancy,
  } = home
  if (hasPool) {
    amenities.push('Pool')
  }
  return (
    <>
      <PhotoCarousel photos={photos} title={title} />
      <Header
        regionName={regionName}
        cityName={cityName}
        title={title}
        roomsCount={roomsCount}
        bathroomsCount={bathroomsCount}
        maxOccupancy={maxOccupancy}
      />
      <hr />
      <Description description={description} />
      <Amenities amenities={amenities} />
    </>
  )
}

export default connector(Details)

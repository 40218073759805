import React, { FC } from 'react'
import { Photo } from 'src/interfaces/home'
import styles from './PhotoCarousel.module.css'

type PhotoCarouselProps = {
  photos: Array<Photo>
  title: string
}

const PhotoCarousel: FC<PhotoCarouselProps> = ({ photos, title }) => {
  const sortedPhotos = photos.sort((a, b) => a.listOrder - b.listOrder)
  return (
    <div className={styles.container}>
      {sortedPhotos.map(({ url, listOrder }) => (
        <img
          key={listOrder}
          src={`https://res.cloudinary.com/marcelotokarnia/image/fetch/h_800,c_fill,f_auto/${url}`}
          alt={`${title} #${listOrder}`}
        />
      ))}
    </div>
  )
}

export default PhotoCarousel

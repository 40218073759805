import React, { FC } from 'react'

const BBQ: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9167 4.56522C11.3537 4.56522 11.7083 4.91583 11.7083 5.34783C11.7083 5.77983 11.3537 6.13043 10.9167 6.13043C9.60488 6.13043 8.54167 7.18148 8.54167 8.47826C8.54167 8.75296 8.58917 9.01591 8.67704 9.26087H3C3 9.79383 3.04513 10.3174 3.13142 10.8261C3.62621 13.7421 5.48029 16.2018 8.02708 17.5479L6.0115 21H7.83946L9.49167 18.1717C10.4369 18.4831 11.4487 18.6522 12.5 18.6522C13.5513 18.6522 14.5631 18.4831 15.5083 18.1717L17.1605 21H18.9885L16.9729 17.5479C19.5197 16.2018 21.3738 13.7421 21.8686 10.8261C21.9549 10.3174 22 9.79383 22 9.26087H15.6667C15.2297 9.26087 14.875 8.91026 14.875 8.47826C14.875 8.04626 15.2297 7.69565 15.6667 7.69565C16.3705 7.69565 17.0022 7.39357 17.4368 6.91304C17.8129 6.49748 18.0417 5.94887 18.0417 5.34783C18.0417 4.05104 16.9785 3 15.6667 3V4.56522C16.1037 4.56522 16.4583 4.91583 16.4583 5.34783C16.4583 5.77983 16.1037 6.13043 15.6667 6.13043C14.3549 6.13043 13.2917 7.18148 13.2917 8.47826C13.2917 8.75296 13.3392 9.01591 13.427 9.26087H10.9167C10.4797 9.26087 10.125 8.91026 10.125 8.47826C10.125 8.04626 10.4797 7.69565 10.9167 7.69565C11.6205 7.69565 12.2522 7.39357 12.6868 6.91304C13.0629 6.49748 13.2917 5.94887 13.2917 5.34783C13.2917 4.05104 12.2285 3 10.9167 3V4.56522ZM4.7417 10.8262H10.9167H15.6667H20.2584C19.5245 14.398 16.3301 17.0871 12.5 17.0871C8.66995 17.0871 5.47557 14.398 4.7417 10.8262Z"
      fill="#022B54"
    />
  </svg>
)

export default BBQ

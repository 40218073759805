import { connect, ConnectedProps } from 'react-redux'
import React, { FC } from 'react'
import Button from 'src/components/styleguide/Button'
import { clearFilters } from 'src/store/actions/thunks'
import { Link } from 'react-router-dom'
import NotFoundSatellite from 'src/assets/NotFoundSatellite'
import styles from './EmptyResults.module.css'

const mapDispatchToProps = {
  clearFilters,
}

const connector = connect(null, mapDispatchToProps)

type EmptyResultsProps = ConnectedProps<typeof connector> & {
  regionName?: string
}

const EmptyResults: FC<EmptyResultsProps> = ({ regionName, clearFilters }) => {
  const regionHomes = `${regionName || ''} homes`
  return (
    <div className={styles.container}>
      <NotFoundSatellite className={styles.satellite} />
      <div className={styles.text}>
        <p>Oops! We haven't found anything matching your search.</p>
        <p>Try something else or reset the filters to see all {regionHomes}</p>
      </div>
      <Link to={regionName ? `/regions/${regionName}` : '/homes'}>
        <Button onClick={() => clearFilters()} label={`See all ${regionHomes}`} />
      </Link>
    </div>
  )
}

export default connector(EmptyResults)

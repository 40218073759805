import { Action, ActionsTypes, ActionsUnion, ActionWithPayload } from 'src/interfaces/store/actions'
import { Home, HomePricing } from 'src/interfaces/home'
import { Region } from 'src/interfaces/filter'

export function createAction<T extends string>(type: T): Action<T>
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload ? { type, payload } : { type }
}

export const Actions = {
  changeCoupon: (p: { coupon: string }) => createAction(ActionsTypes.CHANGE_COUPON, p),
  changeDates: (p: { selectedDates: [string, string] }) =>
    createAction(ActionsTypes.CHANGE_DATES, p),
  changeGuests: (p: { selectedGuests: string }) => createAction(ActionsTypes.CHANGE_GUEST, p),
  changeOrder: (p: { selectedOrder: string }) => createAction(ActionsTypes.CHANGE_ORDER, p),
  changeRegion: (p: { selectedRegion?: string }) => createAction(ActionsTypes.CHANGE_REGION, p),
  clearFilters: () => createAction(ActionsTypes.CLEAR_FILTERS),
  loadHomeDetails: (p: { home: Home; id: string }) =>
    createAction(ActionsTypes.LOAD_HOME_DETAILS, p),
  loadPrices: (p: { keep: boolean; prices: Array<HomePricing> }) =>
    createAction(ActionsTypes.LOAD_PRICES, p),
  loadRegions: (p: { regions: Array<Region> }) => createAction(ActionsTypes.LOAD_REGIONS, p),
  startLoadingPrices: (p: { keep: boolean }) => createAction(ActionsTypes.START_LOAD_PRICES, p),
}

export type Actions = ActionsUnion<typeof Actions>

import React, { FC } from 'react'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Details from 'src/pages/Details'
import Navbar from 'src/components/Navbar'
import Search from 'src/pages/Search'

const App: FC = () => (
  <Router>
    <Navbar />
    <div>
      <Switch>
        <Route exact path="/">
          <Redirect to="/homes" />
        </Route>
        <Route exact path="/homes" component={Search} />
        <Route path="/regions/:region" component={Search} />
        <Route path="/:id/:region/:home" component={Details} />
      </Switch>
    </div>
  </Router>
)

export default App

type FunctionType = (...args: any[]) => any
type ActionCreatorsMapObject = { [actionCreator: string]: FunctionType }
export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>

export interface Action<T extends string> {
  type: T
}

export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P
}

export enum ActionsTypes {
  CHANGE_COUPON = 'store.filters.coupon.change',
  CHANGE_DATES = 'store.filters.dates.change',
  CHANGE_GUEST = 'store.filters.guest.change',
  CHANGE_ORDER = 'store.filters.order.change',
  CHANGE_REGION = 'store.filters.region.change',
  CLEAR_FILTERS = 'store.filters.clear',
  LOAD_HOME_DETAILS = 'store.home.load.details',
  LOAD_PRICES = 'store.homesPricing.load',
  LOAD_REGIONS = 'store.filters.regions.load',
  START_LOAD_PRICES = 'store.homesPricing.start',
}

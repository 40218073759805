import React, { FC } from 'react'

const TV: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.48758 3.23993C8.16849 2.92002 7.64976 2.92002 7.33067 3.23993C7.01076 3.55902 7.01076 4.07774 7.33067 4.39683L10.0249 7.09106H5.45455C4.09882 7.09106 3 8.18987 3 9.54558V18.5455C3 19.9012 4.09882 21 5.45455 21H18.5455C19.9012 21 21 19.9012 21 18.5455V9.54558C21 8.18987 19.9012 7.09106 18.5455 7.09106H13.9751L16.6694 4.39683C16.9893 4.07774 16.9893 3.55902 16.6694 3.23993C16.3503 2.92002 15.8316 2.92002 15.5125 3.23993L12 6.75234L8.48758 3.23993ZM11.9843 8.72747C11.9948 8.72767 12.0053 8.72767 12.0158 8.72747H18.5453C18.997 8.72747 19.3635 9.09401 19.3635 9.54564V18.5455C19.3635 18.9972 18.997 19.3637 18.5453 19.3637H5.45441C5.00278 19.3637 4.63623 18.9972 4.63623 18.5455V9.54564C4.63623 9.09401 5.00278 8.72747 5.45441 8.72747H11.9843Z"
      fill="#022B54"
    />
  </svg>
)

export default TV

import { connect, ConnectedProps } from 'react-redux'
import { Home, HomePricing } from 'src/interfaces/home'
import React, { FC } from 'react'
import CalculatedPricing from './CalculatedPricing'
import HomeCardPhotos from './HomeCardPhotos'
import HomeSpecs from './HomeSpecs'
import { Link } from 'react-router-dom'
import { propEq } from 'ramda'
import { RootState } from 'src/interfaces/store/reducers'
import SeasonPricing from './SeasonPricing'
import styles from './HomeCard.module.css'

const mapStateToProps = ({ selectedDates, prices, pricesLoading }: RootState) => ({
  pricesLoading,
  prices,
  selectedDates,
})

const connector = connect(mapStateToProps)

type ReduxProps = ConnectedProps<typeof connector>

type HomeCardProps = ReduxProps & {
  home: Home
}

const HomeCard: FC<HomeCardProps> = ({
  selectedDates,
  prices,
  pricesLoading,
  home: {
    id,
    cityName,
    stateCode,
    regionName,
    title,
    photos,
    roomsCount,
    bathroomsCount,
    maxOccupancy,
    seasonPricing,
  },
}) => {
  const price = prices.find(propEq('homeId', id)) as HomePricing
  return (
    <Link className={styles.container} to={`/${id}/${regionName}/${title}`}>
      <HomeCardPhotos photos={photos} title={title} />
      <div className={styles['description-container']}>
        <div className={styles.location}>
          {regionName} ・ {cityName}, {stateCode}
        </div>
        <div className={styles.title}>{title}</div>
        <HomeSpecs
          roomsCount={roomsCount}
          bathroomsCount={bathroomsCount}
          maxOccupancy={maxOccupancy}
        />
        {selectedDates?.length ? (
          <CalculatedPricing price={price} loading={pricesLoading} />
        ) : (
          <SeasonPricing seasonPricing={seasonPricing} />
        )}
      </div>
    </Link>
  )
}

export default connector(HomeCard)

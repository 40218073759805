import React, { FC, useEffect, useRef, useState } from 'react'
import { Photo } from 'src/interfaces/home'
import styles from './HomeCardPhotos.module.css'

type HomeCardPhotosProps = {
  photos: Array<Photo>
  title: string
}

const HomeCardPhotos: FC<HomeCardPhotosProps> = ({ photos, title }) => {
  const [idx, setIdx] = useState(0)
  const [isOver, setIsOver] = useState(false)
  const intervalRef = useRef<any>(null)
  const clearRef = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }
  useEffect(() => {
    if (isOver) {
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          if (isOver) {
            setIdx((idx + 1) % photos.length)
          }
        }, 1500)
      }
    } else {
      clearRef()
    }
    return clearRef
  }, [isOver, idx, setIdx, photos.length])

  return (
    <div
      className={styles.picture}
      onMouseOver={() => setIsOver(true)}
      onMouseOut={() => setIsOver(false)}
    >
      <img
        src={`https://res.cloudinary.com/marcelotokarnia/image/fetch/w_400,h_210,c_fill,f_auto/${photos[idx].url}`}
        alt={`${title}'s #${idx}`}
      />
    </div>
  )
}

export default HomeCardPhotos

import { Region } from '../interfaces/filter'

const regions: Array<Region> = [
  {
    id: '0bb20925-51bc-11ea-adb3-c5da55d0cc57',
    name: 'Nashville',
    stateName: 'Tennessee',
  },
  {
    id: '24c6f668-fa74-11e9-aa26-3fdfc21757cb',
    name: 'Sonoma',
    stateName: 'California',
  },
  {
    id: '42b72ed3-4f50-11e9-afc4-934d893b8b54',
    name: 'Malibu',
    stateName: 'California',
  },
  {
    id: '42b755e6-4f50-11e9-afc4-cfeda9af0fec',
    name: 'Coachella Valley',
    stateName: 'California',
  },
  {
    id: '42b77cf7-4f50-11e9-afc4-734d78a6978a',
    name: 'Paso Robles',
    stateName: 'California',
  },
  {
    id: '42b77cf8-4f50-11e9-afc4-3f5e0267ddc0',
    name: 'San Diego',
    stateName: 'California',
  },
  {
    id: '42b7a40a-4f50-11e9-afc4-c9cfd29373e8',
    name: 'Lake Tahoe',
    stateName: 'California',
  },
  {
    id: '42b7cb1b-4f50-11e9-afc4-397f5c2b2d98',
    name: 'Temecula',
    stateName: 'California',
  },
  {
    id: '4603902a-cb72-11e9-a60a-cfc093c1a651',
    name: 'Bend',
    stateName: 'Oregon',
  },
  {
    id: '46a7caf0-7e8c-11ea-b6e6-1c1b0d3c3f82',
    name: 'Breckenridge',
    stateName: 'California',
  },
  {
    id: 'c1bda57a-6214-11e9-a3b5-dd949d424ff2',
    name: 'Scottsdale',
    stateName: 'Arizona',
  },
  {
    id: 'c1be1aab-6214-11e9-a3b5-bdb1d5fa67e4',
    name: 'Newport Beach',
    stateName: 'California',
  },
  {
    id: 'd02d76e0-ebb1-11e9-a94a-bf83202d1910',
    name: 'Park City',
    stateName: 'Utah',
  },
  {
    id: 'd17922c4-1582-11ea-8bdb-a9a358ecac71',
    name: 'Tempe',
    stateName: 'Arizona',
  },
]

export default regions

import React, { FC } from 'react'

const WiFi: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.24137 9.30178C1.43095 9.49112 1.71532 9.5858 1.99969 9.5858C2.28406 9.5858 2.47364 9.49113 2.66322 9.39645C7.97144 4.66273 16.0286 4.66273 21.4315 9.39645C21.8107 9.77515 22.3795 9.68047 22.7587 9.30178C23.1378 8.92308 23.043 8.35503 22.6638 7.97633C16.5973 2.67456 7.4027 2.67456 1.33616 7.97633C0.957003 8.35503 0.862213 8.92308 1.24137 9.30178ZM4.65397 12.5207C4.84355 12.8047 5.12792 12.8994 5.41229 12.8994C5.60187 12.8994 5.88624 12.8994 5.98103 12.6154C9.48824 9.68048 14.6069 9.68048 18.1141 12.6154C18.4933 12.9941 19.062 12.8994 19.4411 12.5207C19.8203 12.142 19.7256 11.574 19.3464 11.1953C15.0809 7.69232 9.0143 7.69232 4.74876 11.1953C4.3696 11.4793 4.27481 12.142 4.65397 12.5207ZM15.3651 16.3077C15.1755 16.3077 14.9859 16.213 14.7963 16.1183C13.1849 14.9822 10.91 14.9822 9.29853 16.1183C8.82461 16.4024 8.25587 16.3077 7.9715 15.9289C7.68713 15.4556 7.78192 14.8876 8.16107 14.6035C10.436 12.9941 13.5641 12.9941 15.839 14.6035C16.313 14.8876 16.4078 15.4556 16.0286 15.9289C15.9338 16.213 15.6494 16.3077 15.3651 16.3077ZM11.6684 19.9054C11.7631 20 11.9527 20 12.0476 20C12.3319 20 12.5215 19.9054 12.7111 19.716C12.9007 19.5266 12.9954 19.3373 12.9954 19.0533C12.9954 18.8639 12.9007 18.5799 12.7111 18.3906C12.3319 18.0118 11.7631 18.0118 11.384 18.3906C11.3366 18.4379 11.3129 18.4853 11.2892 18.5326C11.2655 18.5799 11.2418 18.6273 11.1944 18.6746C11.0996 18.7692 11.0996 18.9586 11.0996 19.0533C11.0996 19.3373 11.1944 19.5266 11.384 19.716C11.4788 19.8106 11.5736 19.9054 11.6684 19.9054Z"
      fill="#022B54"
    />
  </svg>
)

export default WiFi

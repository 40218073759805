import { assoc, assocPath, concat, pipe, range } from 'ramda'
import { GuestOption, HomesOrder, OrderOption, Region } from 'src/interfaces/filter'
import { Actions } from 'src/store/actions'
import { ActionsTypes } from 'src/interfaces/store/actions'
import { extractQueryStringToObject } from 'src/utils'
import { RootState } from 'src/interfaces/store/reducers'

const qs = extractQueryStringToObject(window.location.search)

const wildCardRegion: Region = { id: 'any', name: 'Any Region', stateName: '' }

const initialRegions = { 'Any Region': wildCardRegion }

const initialOrderOptions: Array<OrderOption> = [
  { label: 'Most relevant', value: HomesOrder.RELEVANCE },
  { label: 'Price: Lowest first', value: HomesOrder.PRICE_ASC },
  { label: 'Price: Highest first', value: HomesOrder.PRICE_DESC },
]

const initialGuestOptions: Array<GuestOption> = range(1, 31).map(n => ({
  label: `${n} guest${n === 1 ? '' : 's'}`,
  value: String(n),
}))

const initialState: RootState = {
  coupon: qs.coupon || '',
  guests: initialGuestOptions,
  homes: {},
  orders: initialOrderOptions,
  prices: [],
  pricesLoading: false,
  regions: initialRegions,
  selectedDates: qs.when ? (qs.when.split(',') as [string, string]) : undefined,
  selectedGuests: qs.who || initialGuestOptions[0].value,
  selectedOrder: qs.order || initialOrderOptions[0].value,
  selectedRegion: qs.where || wildCardRegion.id,
}

export default (state = initialState, action: Actions): RootState => {
  switch (action.type) {
    case ActionsTypes.LOAD_REGIONS: {
      return assoc(
        'regions',
        action.payload.regions.reduce(
          (acc, reg) => assoc(reg.name as string, reg, acc),
          initialRegions
        ),
        state
      )
    }
    case ActionsTypes.CHANGE_DATES: {
      return assoc('selectedDates', action.payload.selectedDates, state)
    }
    case ActionsTypes.CHANGE_GUEST: {
      return assoc('selectedGuests', action.payload.selectedGuests, state)
    }
    case ActionsTypes.CHANGE_ORDER: {
      return assoc('selectedOrder', action.payload.selectedOrder, state)
    }
    case ActionsTypes.CHANGE_COUPON: {
      return assoc('coupon', action.payload.coupon, state)
    }
    case ActionsTypes.CHANGE_REGION: {
      return assoc('selectedRegion', action.payload.selectedRegion ?? wildCardRegion.id, state)
    }
    case ActionsTypes.CLEAR_FILTERS: {
      return {
        ...state,
        coupon: '',
        selectedDates: undefined,
        selectedGuests: initialGuestOptions[0].value,
        selectedOrder: initialOrderOptions[0].value,
      }
    }
    case ActionsTypes.LOAD_HOME_DETAILS: {
      return pipe<RootState, RootState, RootState>(
        assoc('home', action.payload.home),
        assocPath(['homes', action.payload.id], action.payload.home)
      )(state)
    }
    case ActionsTypes.LOAD_PRICES: {
      return {
        ...state,
        prices: action.payload.keep
          ? concat(state.prices, action.payload.prices)
          : action.payload.prices,
        pricesLoading: false,
      }
    }
    case ActionsTypes.START_LOAD_PRICES: {
      return { ...state, prices: action.payload.keep ? state.prices : [], pricesLoading: true }
    }
    default:
      return state
  }
}

import React, { FC } from 'react'
import Button from 'src/components/styleguide/Button'
import ChevronDown from 'src/assets/icons/ChevronDown'
import LogoText from 'src/assets/companylogo/Text'
import NavLink from 'src/components/styleguide/NavLink'
import styles from 'src/components/Navbar.module.css'

const Navbar: FC = () => (
  <header className={styles.navbar}>
    <LogoText className={styles.logo} />
    <div className={styles['nav-container']}>
      <NavLink label="Find Homes" active href="/homes" />
      <NavLink label="Partners" href="/" />
      <NavLink label="Company Retreats" href="/" />
      <NavLink label="More" href="/" Icon={ChevronDown} />
    </div>
    <div className={styles['sign-container']}>
      <NavLink label="Sign In" href="/" />
      <Button label="Sign Up" onClick={() => ({})} />
    </div>
  </header>
)

export default Navbar

import React, { FC } from 'react'
import classnames from 'classnames'
import styles from './Button.module.css'

type ButtonProps = {
  label: string
  onClick?: () => void
}

const Button: FC<ButtonProps> = ({ label, onClick }) => (
  <button
    className={classnames(styles.btn, {
      [styles.large]: label.length > 7,
      [styles.xlarge]: label.length > 23,
    })}
    onClick={onClick}
  >
    {label}
  </button>
)

export default Button

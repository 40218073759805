import { clearQueryString, pushQueryString } from 'src/utils'
import { GuestOption, OrderOption, Region } from 'src/interfaces/filter'
import { Action } from 'redux'
import { Actions } from '.'
import api from 'src/api'
import { ApiRequest } from 'src/interfaces/api'
import { propEq } from 'ramda'
import { RootState } from 'src/interfaces/store/reducers'
import { ThunkAction } from 'redux-thunk'

type MyThunkAction = ThunkAction<Promise<void>, RootState, null, Action<string>>

let regionsLoaded = false

export const loadRegions = (initialRegionName?: string): MyThunkAction => async dispatch => {
  if (!regionsLoaded) {
    const regions = await api.regions()
    dispatch(Actions.loadRegions({ regions }))
    if (initialRegionName) {
      dispatch(
        Actions.changeRegion({
          selectedRegion: regions.find(propEq('name', decodeURIComponent(initialRegionName)))?.id,
        })
      )
    }
    regionsLoaded = true
  }
}

export const loadPrices = (
  options: ApiRequest['homesPricing'] & { page: number }
): MyThunkAction => async dispatch => {
  const { page, ...apiOptions } = options
  await dispatch(Actions.startLoadingPrices({ keep: page !== 1 }))
  const prices = await api.homesPricing(apiOptions)
  await dispatch(Actions.loadPrices({ prices, keep: page !== 1 }))
}

export const changeRegion = (selectedRegion: Region): MyThunkAction => async dispatch => {
  dispatch(Actions.changeRegion({ selectedRegion: selectedRegion.id }))
  if (window.history.pushState) {
    const { protocol, host, search } = window.location
    const newurl = `${protocol}//${host}${
      selectedRegion.id === 'any' ? '/homes' : `/regions/${encodeURIComponent(selectedRegion.name)}`
    }${search}`
    window.history.pushState({ path: newurl }, '', newurl)
  }
}

export const changeDates = (selectedDates: [string, string]): MyThunkAction => async dispatch => {
  dispatch(Actions.changeDates({ selectedDates }))
  pushQueryString('when', selectedDates.join(','))
}

export const changeGuests = (selectedGuests: GuestOption): MyThunkAction => async dispatch => {
  dispatch(Actions.changeGuests({ selectedGuests: selectedGuests.value }))
  pushQueryString('who', selectedGuests.value)
}

export const changeOrder = (selectedOrder: OrderOption): MyThunkAction => async dispatch => {
  dispatch(Actions.changeOrder({ selectedOrder: selectedOrder.value }))
  pushQueryString('order', selectedOrder.value)
}

export const changeCoupon = (coupon = ''): MyThunkAction => async (dispatch, getState) => {
  const { coupon: stateCoupon } = getState()
  if (stateCoupon !== coupon) {
    dispatch(Actions.changeCoupon({ coupon }))
    pushQueryString('coupon', coupon)
  }
}

export const clearFilters = (): MyThunkAction => async dispatch => {
  clearQueryString()
  dispatch(Actions.clearFilters())
}

export const loadHomeDetails = (id: string): MyThunkAction => async (dispatch, getState) => {
  const { homes } = getState()
  if (homes[id]) {
    dispatch(Actions.loadHomeDetails({ home: homes[id], id }))
  } else {
    const home = await api.home({ id })
    dispatch(Actions.loadHomeDetails({ home, id }))
  }
}

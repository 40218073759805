import AC from './AC'
import BBQ from './BBQ'
import Cleaning from './Cleaning'
import FirePit from './FirePit'
import Laundry from './Laundry'
import Others from './Others'
import Parking from './Parking'
import Pets from './Pets'
import Pool from './Pool'
import TV from './TV'
import WiFi from './WiFi'

export default {
  'Air Conditioning': AC,
  BBQ: BBQ,
  'Cable/satellite TV': TV,
  Cleaning: Cleaning,
  'Fast WiFi': WiFi,
  Fireplace: FirePit,
  'Free Parking': Parking,
  'Heated outdoor pool': Pool,
  Internet: WiFi,
  'Internet Access': WiFi,
  Jacuzzi: Pool,
  Others: Others,
  Parking: Parking,
  Pets: Pets,
  Pool: Pool,
  'Satellite or Cable': WiFi,
  TV: TV,
  Television: TV,
  Washer: Laundry,
  'Washing machine': Laundry,
  WiFi: WiFi,
}

import {
  changeCoupon,
  changeDates,
  changeGuests,
  changeOrder,
  changeRegion,
} from 'src/store/actions/thunks'
import { connect, ConnectedProps } from 'react-redux'
import Field, { InputTypes } from 'src/components/styleguide/Field'
import { GuestOption, OrderOption, Region } from 'src/interfaces/filter'
import React, { FC } from 'react'
import { append } from 'ramda'
import { RootState } from 'src/interfaces/store/reducers'
import styles from './SearchBar.module.css'

type RegionOption = {
  label: string
  region: Region
  value: string
}

const mapStateToProps = ({
  regions,
  coupon,
  orders,
  selectedGuests,
  selectedDates,
  selectedOrder,
  selectedRegion,
  guests,
}: RootState) => ({
  coupon,
  guests,
  orders,
  regions: Object.keys(regions).reduce<Array<RegionOption>>(
    (acc, label) => append({ label, value: regions[label].id, region: regions[label] }, acc),
    []
  ),
  selectedDates,
  selectedGuests,
  selectedOrder,
  selectedRegion,
})

const mapDispatchToProps = {
  changeCoupon,
  changeDates,
  changeGuests,
  changeOrder,
  changeRegion,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type SearchBarProps = ConnectedProps<typeof connector>

const SearchBar: FC<SearchBarProps> = ({
  changeDates,
  changeGuests,
  changeOrder,
  changeCoupon,
  coupon,
  changeRegion,
  guests,
  regions,
  orders,
  selectedDates,
  selectedGuests,
  selectedOrder,
  selectedRegion,
}) => (
  <div className={styles.searchbar}>
    <Field
      className={styles.where}
      type={InputTypes.SELECT}
      title="Where"
      options={regions}
      onChange={p => changeRegion((p as RegionOption).region)}
      initialValue={selectedRegion}
    />
    <Field
      className={styles.when}
      type={InputTypes.DATE}
      initialValue={selectedDates?.join(',')}
      title="When"
      placeholder="Select..."
      onChange={v => changeDates(v as [string, string])}
    />
    <Field
      className={styles.who}
      type={InputTypes.SELECT}
      initialValue={selectedGuests}
      title="Who"
      onChange={v => changeGuests(v as GuestOption)}
      options={guests}
    />
    <Field
      className={styles.order}
      type={InputTypes.SELECT}
      title="Order"
      onChange={v => changeOrder(v as OrderOption)}
      initialValue={selectedOrder}
      options={orders}
    />
    <Field
      initialValue={coupon}
      className={styles.coupon}
      type={InputTypes.TEXT}
      title="Coupon"
      onBlur={(_, value) => changeCoupon(value)}
      onKeyDown={(e, value) => e.key === 'Enter' && changeCoupon(value)}
      placeholder="Cot a code ?"
    />
  </div>
)

export default connector(SearchBar)

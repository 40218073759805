import React, { FC } from 'react'
import styles from './ResultsHeader.module.css'

type ResultsHeaderProps = {
  count: number
}

const ResultsHeader: FC<ResultsHeaderProps> = ({ count }) => (
  <>
    <div className={styles.text1}>Your stay in one of</div>
    <div className={styles.text2}>
      <b>{count}</b> Homes
    </div>
  </>
)

export default ResultsHeader

import { drop, isEmpty, prepend, reject, take } from 'ramda'
import React, { FC, useEffect, useState } from 'react'
import amenitiesMap from 'src/assets/amenities'
import AmenitiesTable from 'src/components/details/AmenitiesTable'
import styles from './Amenities.module.css'

type AmenitiesProps = {
  amenities: Array<string>
}

const groupsOf3 = (list: Array<string>): Array<Array<string>> =>
  isEmpty(list) ? [] : prepend(take(3, list), groupsOf3(drop(3, list)))

const Amenities: FC<AmenitiesProps> = ({ amenities }) => {
  const [namedAmenitiesGroups, setNamedAmenitiesGroups] = useState<Array<Array<string>>>([[]])
  const [otherAmenitiesGroups, setOtherAmenitiesGroups] = useState<Array<Array<string>>>([[]])
  useEffect(() => {
    setNamedAmenitiesGroups(groupsOf3(amenities.filter(a => a in amenitiesMap)))
    setOtherAmenitiesGroups(groupsOf3(reject(a => a in amenitiesMap, amenities)))
  }, [amenities])

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Amenities</h1>
      <AmenitiesTable amenitiesGroups={namedAmenitiesGroups} />
      <h3 className={styles.more}>And a couple more...</h3>
      <AmenitiesTable amenitiesGroups={otherAmenitiesGroups} />
    </div>
  )
}

export default Amenities

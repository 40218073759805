import React, { FC } from 'react'

const Cleaning: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0472 10.0472V4.41416C13.0472 3.96996 12.6867 3.60944 12.2425 3.60944C11.7983 3.60944 11.4378 3.96996 11.4378 4.41416V10.0472H13.0472ZM9.82833 4.41416V10.0472H9.36561C8.22774 10.0472 7.24356 10.8423 7.00536 11.9552L5 21.3133H19.485L17.4796 11.9552C17.2414 10.8423 16.2572 10.0472 15.1194 10.0472H14.6567V4.41416C14.6567 3.08074 13.5759 2 12.2425 2C10.9091 2 9.82833 3.08074 9.82833 4.41416ZM9.36561 11.6567H9.82833H14.6567H15.1194C15.4984 11.6567 15.8267 11.9214 15.9064 12.2924L15.9418 12.4614H8.54319L8.57859 12.2924C8.65826 11.9214 8.98659 11.6567 9.36561 11.6567ZM8.19927 14.0708L6.99219 19.7038H9.31703L9.5689 16.4238C9.6027 15.9804 9.98977 15.6489 10.4332 15.6827C10.8758 15.7165 11.2073 16.1035 11.1735 16.5461L10.9313 19.7038H13.5748L13.3326 16.5461C13.2988 16.1035 13.6303 15.7165 14.0729 15.6827C14.5163 15.6489 14.9034 15.9804 14.9372 16.4238L15.1891 19.7038H17.4954L16.2883 14.0708H8.19927Z"
      fill="#022B54"
    />
  </svg>
)

export default Cleaning

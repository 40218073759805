import DateField, { DateProps } from './DateField'
import React, { FC } from 'react'
import SelectField, { SelectProps } from './SelectField'
import TextField, { TextProps } from './TextField'
import classnames from 'classnames'
import styles from './Field.module.css'

export enum InputTypes {
  DATE = 'date',
  SELECT = 'select',
  TEXT = 'text',
}

export type CommonProps = {
  className?: string
  initialValue?: string
  placeholder?: string
  title: string
}

type FieldProps = TextProps | DateProps | SelectProps

const isText = (props: FieldProps): props is TextProps => props.type === InputTypes.TEXT

const isSelect = (props: FieldProps): props is SelectProps => props.type === InputTypes.SELECT

const Field: FC<FieldProps> = props => {
  return (
    <div className={classnames(styles.field, props.className)}>
      <label className={styles.title}>{props.title}</label>
      {isText(props) ? (
        <TextField {...props} />
      ) : isSelect(props) ? (
        <SelectField {...props} />
      ) : (
        <DateField {...props} />
      )}
    </div>
  )
}

export default Field

import React, { FC } from 'react'
import amenitiesMap from 'src/assets/amenities'
import styles from './AmenitiesTable.module.css'

type AmenitiesTableProps = {
  amenitiesGroups: Array<Array<string>>
}

type ItemProps = {
  amenitie: string
}

const Item: FC<ItemProps> = ({ amenitie }) => {
  const Component: FC = (amenitiesMap as any)[amenitie] || amenitiesMap.Others
  return (
    <div>
      <Component />
      <div className={styles.name}>{amenitie}</div>
    </div>
  )
}

const AmenitiesTable: FC<AmenitiesTableProps> = ({ amenitiesGroups }) => {
  if (!amenitiesGroups.length || !amenitiesGroups[0].length) return null
  return (
    <>
      {amenitiesGroups.map((g, idx) => (
        <div key={idx} className={styles.line}>
          {g.map((a, jdx) => (
            <div key={`${idx}-${jdx}`} className={styles.item}>
              <Item amenitie={a} />
            </div>
          ))}
          {g.length === 2 ? <div className={styles.item} /> : null}
        </div>
      ))}
    </>
  )
}

export default AmenitiesTable

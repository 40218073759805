import React, { FC, useState } from 'react'
import classnames from 'classnames'
import styles from './Description.module.css'

type DescriptionProps = {
  description: string
}

const Description: FC<DescriptionProps> = ({ description }) => {
  const [collapsed, setCollapsed] = useState(true)
  const handleOnClick = () => {
    setCollapsed(!collapsed)
  }
  return (
    <div className={classnames(styles.container, { [styles.collapsed]: collapsed })}>
      <div className={styles['multiline-ellipsis']}>{description}</div>
      <button
        onClick={handleOnClick}
        className={classnames(styles.toggle, { hide: !styles.collapsed })}
      >
        {collapsed ? 'View more' : 'Collapse back'}
      </button>
    </div>
  )
}

export default Description

import { CommonProps, InputTypes } from './Field'
import React, { FC, useState } from 'react'
import classnames from 'classnames'
import styles from './Field.module.css'

export type TextProps = CommonProps & {
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, value?: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>, value?: string) => void
  type: InputTypes.TEXT
}

const TextField: FC<TextProps> = props => {
  const [value, setValue] = useState(props.initialValue)
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => props.onKeyDown?.(e, value)
  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => props.onBlur?.(e, value)
  return (
    <input
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      onBlur={handleOnBlur}
      className={classnames({ [styles.placeholder]: !value })}
      type={props.type}
      value={value}
      placeholder={props.placeholder}
    />
  )
}
export default TextField

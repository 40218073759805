import 'rsuite/dist/styles/rsuite-default.css'
import 'src/index.css'
import App from 'src/App'
import configureStore from 'src/store'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

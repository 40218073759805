import React, { FC } from 'react'

type NotFoundSatelliteProps = {
  className?: string
}

const Satellite: FC<NotFoundSatelliteProps> = ({ className }) => (
  <svg
    className={className}
    width="186"
    height="174"
    viewBox="0 0 186 174"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M84.0943 72.3569L73.6506 62.8256L68.4271 68.5593L78.8708 78.0906L84.0943 72.3569Z"
      fill="#022B54"
    />
    <path
      d="M84.894 72.32L73.6134 62.0249L67.6274 68.5962L78.908 78.8914L84.894 72.32ZM73.6881 63.6263L83.2941 72.3937L78.8332 77.2899L69.2273 68.5226L73.6881 63.6263Z"
      fill="#FFFEEF"
    />
    <path
      d="M0.000267029 21.8921L60.0136 76.6633L61.0895 76.6137L79.9126 54.8209L20.9751 0.00012207L19.8992 0.0497221L0.000267029 21.8921Z"
      fill="#022B54"
    />
    <path
      d="M80.9883 54.7712L20.9748 -7.60854e-07L1.07586 21.8426L61.0893 76.6139L80.9883 54.7712Z"
      fill="white"
    />
    <path
      d="M76.3946 59.8134L16.3817 5.04238L15.6192 5.87938L75.6321 60.6504L76.3946 59.8134Z"
      fill="#022B54"
    />
    <path
      d="M71.42 65.2743L11.4072 10.5027L10.6446 11.3397L70.6575 66.1113L71.42 65.2743Z"
      fill="#022B54"
    />
    <path
      d="M66.4449 70.7345L6.43198 15.9635L5.66943 16.8005L65.6824 71.5715L66.4449 70.7345Z"
      fill="#022B54"
    />
    <path
      d="M75.9455 50.1681L75.1081 49.4048L55.2211 71.258L56.0584 72.0214L75.9455 50.1681Z"
      fill="#022B54"
    />
    <path
      d="M70.4894 45.1892L69.6521 44.4259L49.765 66.2791L50.6023 67.0425L70.4894 45.1892Z"
      fill="#022B54"
    />
    <path
      d="M65.0339 40.2099L64.1965 39.4465L44.3094 61.2998L45.1467 62.0632L65.0339 40.2099Z"
      fill="#022B54"
    />
    <path
      d="M59.5773 35.231L58.74 34.4677L38.8533 56.3206L39.6906 57.0839L59.5773 35.231Z"
      fill="#022B54"
    />
    <path
      d="M54.1222 30.2515L53.2849 29.4881L33.3975 51.3418L34.2348 52.1052L54.1222 30.2515Z"
      fill="#022B54"
    />
    <path
      d="M48.6652 25.271L47.8278 24.5078L27.9426 46.3636L28.78 47.1268L48.6652 25.271Z"
      fill="#022B54"
    />
    <path
      d="M43.2106 20.2934L42.3733 19.53L22.4865 41.383L23.3238 42.1463L43.2106 20.2934Z"
      fill="#022B54"
    />
    <path
      d="M37.755 15.3141L36.9177 14.5508L17.0306 36.404L17.868 37.1674L37.755 15.3141Z"
      fill="#022B54"
    />
    <path
      d="M32.299 10.3346L31.4617 9.57127L11.5746 31.4245L12.4119 32.1879L32.299 10.3346Z"
      fill="#022B54"
    />
    <path
      d="M26.8429 5.35575L26.0056 4.59237L6.11877 26.4454L6.95612 27.2087L26.8429 5.35575Z"
      fill="#022B54"
    />
    <path
      d="M165.848 173.253L105.835 118.482L105.785 117.405L125.734 96.6394L185.698 150.334L185.747 151.411L165.848 173.253Z"
      fill="#022B54"
    />
    <path
      d="M102.679 99.8193L113.123 109.351L118.346 103.617L107.903 94.0856L102.679 99.8193Z"
      fill="#022B54"
    />
    <path
      d="M119.146 103.58L107.865 93.2848L101.88 99.8561L113.16 110.151L119.146 103.58ZM107.939 94.8862L117.546 103.654L113.086 108.55L103.48 99.7825L107.939 94.8862Z"
      fill="#FFFEEF"
    />
    <path
      d="M185.697 150.334L125.684 95.5625L105.785 117.405L165.798 172.176L185.697 150.334Z"
      fill="white"
    />
    <path
      d="M181.104 155.376L121.09 100.605L120.328 101.442L180.342 156.213L181.104 155.376Z"
      fill="#022B54"
    />
    <path
      d="M176.129 160.837L116.115 106.065L115.353 106.902L175.367 161.674L176.129 160.837Z"
      fill="#022B54"
    />
    <path
      d="M171.154 166.297L111.14 111.526L110.378 112.363L170.392 167.134L171.154 166.297Z"
      fill="#022B54"
    />
    <path
      d="M131.552 100.918L130.715 100.154L110.828 122.008L111.665 122.772L131.552 100.918Z"
      fill="#022B54"
    />
    <path
      d="M137.008 105.897L136.171 105.134L116.283 126.988L117.121 127.751L137.008 105.897Z"
      fill="#022B54"
    />
    <path
      d="M142.463 110.876L141.626 110.113L121.739 131.966L122.576 132.73L142.463 110.876Z"
      fill="#022B54"
    />
    <path
      d="M147.918 115.854L147.08 115.091L127.197 136.947L128.034 137.71L147.918 115.854Z"
      fill="#022B54"
    />
    <path
      d="M153.374 120.834L152.537 120.071L132.652 141.926L133.489 142.689L153.374 120.834Z"
      fill="#022B54"
    />
    <path
      d="M158.831 125.814L157.994 125.051L138.107 146.904L138.944 147.667L158.831 125.814Z"
      fill="#022B54"
    />
    <path
      d="M164.287 130.793L163.45 130.03L143.563 151.883L144.4 152.647L164.287 130.793Z"
      fill="#022B54"
    />
    <path
      d="M169.743 135.772L168.905 135.009L149.018 156.863L149.855 157.626L169.743 135.772Z"
      fill="#022B54"
    />
    <path
      d="M175.199 140.751L174.362 139.988L154.474 161.842L155.311 162.605L175.199 140.751Z"
      fill="#022B54"
    />
    <path
      d="M180.653 145.73L179.816 144.967L159.93 166.821L160.767 167.585L180.653 145.73Z"
      fill="#022B54"
    />
    <path
      d="M141.222 33.5806C132.86 25.9495 124.968 20.9858 123.595 22.4938L104.66 43.2783L134.939 70.9128L153.874 50.1282C155.248 48.6203 149.583 41.2117 141.222 33.5806Z"
      fill="#53C3D0"
    />
    <path
      d="M134.939 70.9128C133.565 72.4208 125.673 67.4569 117.312 59.8259C108.95 52.1948 103.286 44.7862 104.659 43.2783C106.033 41.7704 113.925 46.7341 122.286 54.3652C130.648 61.9964 136.313 69.4049 134.939 70.9128Z"
      fill="#022B54"
    />
    <path
      d="M128.916 60.966C130.9 63.0544 132.67 65.3358 134.2 67.7764L153.135 46.9918C151.605 44.5512 149.835 42.2697 147.851 40.1813L128.916 60.966Z"
      fill="#022B54"
    />
    <path
      d="M125.481 57.3974C126.228 58.1346 126.94 58.8603 127.62 59.5745L146.555 38.7897C145.876 38.0772 145.164 37.3516 144.416 36.6128L125.481 57.3974Z"
      fill="#022B54"
    />
    <path
      d="M138.648 66.8417C139.147 64.4298 132.771 56.9192 125.776 50.5349C118.781 44.1509 110.722 38.4879 108.368 39.2071L108.056 39.5504L108.158 39.6437C109.314 38.3746 116.594 42.5304 125.589 50.7397C134.584 58.9489 139.389 65.8224 138.233 67.0914L138.335 67.1849L138.648 66.8417Z"
      fill="#022B54"
    />
    <path
      d="M150.855 53.4423C151.354 51.0304 144.978 43.5198 137.983 37.1355C130.988 30.7515 122.929 25.0884 120.575 25.8077L120.263 26.151L120.365 26.2443C121.521 24.9753 128.801 29.1311 137.796 37.3403C146.792 45.5496 151.596 52.4231 150.44 53.6921L150.542 53.7855L150.855 53.4423Z"
      fill="#022B54"
    />
    <path
      d="M120.421 56.4137C115.298 51.7389 110.464 48.698 109.622 49.6217L89.1651 72.0769L107.715 89.0061L128.172 66.551C129.013 65.6272 125.543 61.0886 120.421 56.4137Z"
      fill="#000116"
    />
    <path
      d="M120.421 56.4137C115.298 51.7389 110.464 48.698 109.622 49.6217L89.1651 72.0769L107.715 89.0061L128.172 66.551C129.013 65.6272 125.543 61.0886 120.421 56.4137Z"
      fill="#022B54"
    />
    <path
      d="M128.589 66.9334C128.997 66.4853 129.274 65.5435 126.982 62.5444C125.11 60.1905 123.043 57.9995 120.801 55.9949V55.9954C120.406 55.6343 111.088 47.1763 109.203 49.2397L88.3644 72.1133L107.752 89.8071L128.589 66.9334ZM110.001 50.033C110.663 49.7813 114.567 51.839 120.039 56.8324C125.51 61.8262 127.918 65.5363 127.752 66.1695L107.677 88.2057L89.9644 72.0397L110.001 50.033Z"
      fill="#FFFEEF"
    />
    <path
      d="M111.327 66.3957C102.965 58.7646 95.0735 53.8009 93.6998 55.3088L60.3068 91.9635L90.5863 119.598L123.979 82.9433C125.353 81.4354 119.688 74.0267 111.327 66.3957Z"
      fill="#53C3D0"
    />
    <path
      d="M90.5862 119.598C89.2124 121.106 81.3205 116.142 72.959 108.511C64.5976 100.88 58.933 93.4714 60.3067 91.9635C61.6804 90.4555 69.5723 95.4193 77.9338 103.05C86.2952 110.681 91.96 118.09 90.5862 119.598Z"
      fill="#022B54"
    />
    <path
      d="M95.452 114.257C95.9512 111.845 89.5756 104.334 82.5804 97.9497C75.5852 91.5657 67.5263 85.9029 65.1725 86.6221L64.8598 86.9654L64.9621 87.0588C66.1184 85.7895 73.3987 89.9453 82.3937 98.1546C91.3888 106.364 96.1933 113.237 95.037 114.507L95.1393 114.6L95.452 114.257Z"
      fill="#022B54"
    />
    <path
      d="M119.069 88.3326C119.568 85.9207 113.193 78.41 106.197 72.0259C99.2022 65.6417 91.1436 59.9787 88.7897 60.6979L88.4771 61.0412L88.5793 61.1346C89.7355 59.8655 97.0158 64.0214 106.011 72.2306C115.006 80.4398 119.81 87.3134 118.654 88.5824L118.757 88.6758L119.069 88.3326Z"
      fill="#022B54"
    />
    <path
      d="M84.5632 109.651C86.5467 111.74 88.3168 114.021 89.8472 116.462L123.24 79.807C121.71 77.3664 119.94 75.0849 117.956 72.9965L84.5632 109.651Z"
      fill="#022B54"
    />
    <path
      d="M81.1283 106.083C81.8747 106.82 82.5875 107.546 83.2668 108.26L116.66 71.6049C115.981 70.8923 115.269 70.1667 114.521 69.428L81.1283 106.083Z"
      fill="#022B54"
    />
    <path
      d="M89.178 66.9479C89.6245 66.4579 90.3833 66.423 90.8729 66.8698C91.3624 67.3167 91.3974 68.0762 90.9509 68.5662C90.5045 69.0562 89.7457 69.0912 89.2561 68.6444C89.021 68.4298 88.8807 68.1305 88.866 67.8123C88.8514 67.4942 88.9636 67.1832 89.178 66.9479Z"
      fill="#022B54"
    />
    <path
      d="M85.1672 71.3506C85.6137 70.8605 86.3725 70.8255 86.8621 71.2724C87.3517 71.7193 87.3866 72.4788 86.9402 72.9688C86.4937 73.4588 85.7349 73.4938 85.2453 73.0469C85.0102 72.8324 84.8699 72.5331 84.8552 72.2149C84.8406 71.8968 84.9528 71.5859 85.1672 71.3506Z"
      fill="#022B54"
    />
    <path
      d="M81.1563 75.7531C81.4832 75.3942 81.994 75.2678 82.4504 75.4328C82.9069 75.5978 83.219 76.0218 83.2414 76.507C83.2637 76.9922 82.9919 77.4431 82.5526 77.6494C82.1132 77.8558 81.593 77.7769 81.2344 77.4496C80.9993 77.235 80.8589 76.9357 80.8443 76.6176C80.8296 76.2994 80.9418 75.9885 81.1563 75.7531Z"
      fill="#022B54"
    />
    <path
      d="M84.8056 117.324C85.7668 116.269 81.6123 110.911 75.5265 105.357C69.4405 99.8024 63.7278 96.155 62.7666 97.21L44.0847 99.2596L84.4862 136.132L84.8056 117.324Z"
      fill="#022B54"
    />
    <path
      d="M84.7098 136.653C84.9142 136.564 85.0478 136.364 85.0514 136.142L85.3682 117.509C85.6965 116.937 85.6407 115.756 83.2554 112.68C78.7274 107.166 73.431 102.332 67.5288 98.3268C64.2523 96.2359 63.0711 96.2861 62.5308 96.6658L44.023 98.6961C43.8018 98.7204 43.6154 98.872 43.5464 99.0837C43.4775 99.2954 43.5388 99.5279 43.7032 99.6779L84.1041 136.551C84.2685 136.701 84.5055 136.741 84.7098 136.653ZM62.8282 97.7731C62.9565 97.7649 63.0764 97.7064 63.1618 97.6103C63.8811 97.2701 68.4946 99.7067 75.1452 105.775C81.7625 111.815 84.618 116.182 84.3744 116.957C84.2893 117.056 84.2415 117.183 84.2394 117.314L83.9412 134.868L45.3925 99.6858L62.8282 97.7731Z"
      fill="#FFFEEF"
    />
    <path
      d="M84.2375 136.773C82.4595 138.725 71.8921 131.978 60.6345 121.703C49.3768 111.429 41.6921 101.518 43.4699 99.5665C45.2478 97.6148 55.8154 104.362 67.073 114.636C78.3307 124.91 86.0154 134.821 84.2375 136.773Z"
      fill="#022B54"
    />
    <path
      d="M43.4696 99.5665C43.5029 99.1619 43.919 98.9161 44.253 98.8442C44.5855 98.7932 44.9249 98.8125 45.2495 98.9009C45.8631 99.0296 46.4622 99.2198 47.0378 99.4685C48.1582 99.942 49.2448 100.492 50.29 101.114C52.3633 102.339 54.3723 103.67 56.3091 105.102C64.0559 110.791 71.1749 117.29 77.5456 124.489C79.1478 126.287 80.6559 128.167 82.0641 130.122C82.778 131.107 83.425 132.138 84.0007 133.21C84.2972 133.762 84.5416 134.34 84.7307 134.938C84.8364 135.256 84.8892 135.59 84.8871 135.926C84.8804 136.321 84.6166 136.665 84.2372 136.773C84.1006 134.62 82.6769 132.547 81.333 130.645C79.9255 128.73 78.4201 126.89 76.8229 125.131C70.3722 118.107 63.3143 111.668 55.7316 105.888C53.8339 104.459 51.8642 103.129 49.8303 101.903C47.8143 100.727 45.6212 99.5343 43.4696 99.5665Z"
      fill="#FFFEEF"
    />
    <path
      d="M61.5311 120.719C56.9817 116.63 52.7413 112.21 48.8447 107.493C45.3063 103.078 44.6929 101.199 44.5931 100.591C45.2063 100.635 47.1329 101.074 51.8506 104.194C61.9077 111.175 70.9915 119.465 78.8627 128.846C82.4009 133.261 83.0145 135.141 83.1143 135.748C82.5011 135.704 80.5746 135.266 75.8567 132.146C70.806 128.696 66.0177 124.877 61.5311 120.719Z"
      fill="#53C3D0"
    />
    <path
      d="M63.2343 118.849C58.6793 114.755 54.4339 110.329 50.5327 105.607C49.572 104.425 48.6715 103.195 47.8345 101.922L48.0619 101.781C48.8934 103.046 49.7881 104.268 50.7424 105.443C54.6344 110.153 58.8697 114.568 63.4136 118.652C67.8948 122.805 72.6772 126.62 77.7216 130.065C78.978 130.908 80.2761 131.688 81.6108 132.4L81.4921 132.639C80.1487 131.922 78.8421 131.138 77.5776 130.289C72.5206 126.836 67.7263 123.012 63.2343 118.849Z"
      fill="#022B54"
    />
    <path
      d="M65.1274 116.772C61.4311 113.426 57.935 109.866 54.6572 106.109C62.7166 111.988 70.1079 118.734 76.6987 126.225C72.6594 123.304 68.7956 120.148 65.1274 116.772Z"
      fill="#022B54"
    />
    <path
      d="M61.0525 118.357L63.1495 120.271L66.9843 116.062L64.8873 114.148L61.0525 118.357Z"
      fill="#022B54"
    />
    <path
      d="M61.3704 117.285C62.0649 116.523 63.2453 116.468 64.0069 117.163C64.7685 117.858 64.823 119.04 64.1285 119.802C63.434 120.565 62.2536 120.619 61.492 119.924C61.1262 119.59 60.9079 119.125 60.8851 118.63C60.8623 118.135 61.0369 117.651 61.3704 117.285Z"
      fill="#022B54"
    />
  </svg>
)

export default Satellite
